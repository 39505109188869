import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../../../api/aipDjapi'


function submitOptIn(store_full_name, phone_number){
  const form = new FormData()

  form.append('store_full_name', store_full_name)
  form.append('phone_number', phone_number)
  return AIP_DJAPI(
    AIP_DJAPI_URLS.CONSUMER_OPT_IN,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
      },
      body: form
    }
  )
}

export default {
  submitOptIn
}
