let vehicleInfoCached = null
let thirdPartyTagsCached = null

export function initThirdPartyTags(thirdPartyTags, vehicle){
  vehicleInfoCached = vehicle
  thirdPartyTagsCached = thirdPartyTags
  if(thirdPartyTagsCached['google-analytics'] && thirdPartyTagsCached['google-analytics'].length){
    thirdPartyTagsCached['google-analytics'].forEach(tag=>{
      // Create the first script tag for loading gtag.js
      var gtagScript = document.createElement("script")
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag.measurement_id}`
      gtagScript.async = true

      // Create the second script tag for gtag configuration
      var gtagConfigScript = document.createElement("script")
      gtagConfigScript.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${tag.measurement_id}');
      `
      document.body.insertBefore(gtagScript, document.body.firstChild);
      document.body.insertBefore(gtagConfigScript, gtagScript.nextSibling);
    })
  }
  if(thirdPartyTagsCached['tealium'] && thirdPartyTagsCached['tealium'].length){
    const urlParams = new URLSearchParams(window.location.search);
    const tealiumVisitorId = urlParams.get('TMVID')
    if(tealiumVisitorId){
      localStorage.setItem("tealium_visitor_id", tealiumVisitorId);
    }
    thirdPartyTagsCached['tealium'].forEach(tag=>{
      console.log('Tealium script inserted')
      var tealiumScript = document.createElement("script")
      tealiumScript.src = `//tags.tiqcdn.com/utag/${tag.account}/${tag.profile}/${tag.environment}/utag.js`
      tealiumScript.type = "text/javascript"
      tealiumScript.async = true
      tealiumScript.onload = function() {
        sendVehicleEvent({
          event: 'asc_pageview',
        })
      };
      document.body.insertBefore(tealiumScript, document.body.firstChild)
    })
  }
}

export function sendVehicleEvent(params){
  let event_params = {...params}
  console.log('Vehicle Info', vehicleInfoCached)
  console.log(event_params)
  if(vehicleInfoCached){
    event_params= {
      item_id: vehicleInfoCached.vin,
      item_number: vehicleInfoCached.stock_no,
      item_price: vehicleInfoCached.price,
      item_condition: vehicleInfoCached.stock_type === 'U' ? 'used': 'new',
      item_make: vehicleInfoCached.make,
      item_model: vehicleInfoCached.model,
      item_variant: vehicleInfoCached.trim,
      item_color: vehicleInfoCached.ext_color,
      item_type: vehicleInfoCached.body_type,
      ...event_params
    }
  }
  sendEvent(event_params)
}
export function sendEvent(params){
  try{
    let event_params = {
      event_owner: 'ipacket',
      page_type: 'item',
      ...params
    }
    console.log(event_params)

    if(typeof window.gtag === "function" && thirdPartyTagsCached['google-analytics'] && thirdPartyTagsCached['google-analytics'].length){
      gtag('event',event_params.event, {...event_params,event:undefined })
    }
    else if(typeof window.gtag !== "function" && utag){
      utag.link(event_params)
    }
  }
  catch(e){
    console.log(e)
  }
}

export default {
  initThirdPartyTags,
  sendVehicleEvent,
  sendEvent,
}
